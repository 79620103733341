<template>
  <div class="card mb-xl-10 border border-primary comentarios mb-5 mx-9 mt-10">
    <div class="card-body p-1 py-5 m-0">
      <div class="d-flex align-items-center">
        <div class="col-1 text-center">
          <i class="fa fa-info-circle fs-1 text-primary"></i>
        </div>
        <div class="fs-4 fw-bold mb-3 col-11">
          <div class="mb-3 colorTitle">
            {{ $t("solicitud.titulo") }}
          </div>
          <div class="fs-7 fw-normal me-5">
            {{ $t("solicitud.yaContestado") }}
            <a href="mailto:iam@aragon.es">
              {{ $t("solicitud.correo") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
